<template>
    <button @click="print"></button>
</template>
<script>
import printJS from 'print-js'

export default ({
    methods:{
        print(){
             printJS({printable:'https://dealersgearcrm.com//tracker-module.pdf', type:'pdf', showModal:true})
        }
    }
})
</script>
